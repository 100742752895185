import { Experience as ExperienceType } from "@/types/cv"

interface ExperienceProps {
  experience: ExperienceType[]
}

export function Experience({ experience }: ExperienceProps) {
  return (
    <div className="space-y-4 md:space-y-6">
      {experience.map((job, index) => (
        <div key={index} className="space-y-1.5 md:space-y-2">
          <div className="flex flex-col md:flex-row md:justify-between md:items-start gap-0.5 md:gap-0">
            <div>
              <h3 className="font-semibold text-foreground">{job.title}</h3>
              <p className="text-sm text-muted-foreground">
                {job.company}, {job.location}
              </p>
            </div>
            <span className="text-sm text-muted-foreground order-first md:order-none">{job.period}</span>
          </div>
          <ul className="list-disc list-inside text-sm space-y-0.5 md:space-y-1 text-muted-foreground pl-1 md:pl-0">
            {job.description.map((item, index) => (
              <li key={index} className="leading-normal">{item}</li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  )
} 