import { CV } from '@/types/cv'

export const cv: CV = {
    name: "John Doe",
    contact: {
      email: "john.doe@email.com",
      phone: "+1 234 567 8900",
      address: "123 Main Street, City, Country",
      picture: {
        url: "",
        visible: false,
        round: true
      }
    },
    experience: [
      {
        title: "Founder & CTO",
        company: "Tech Startup",
        location: "City",
        period: "2023 - Present",
        description: [
          "Founded a B2B SaaS company focusing on enterprise solutions.",
          "Led technical development and commercial strategy, successfully launching the platform and securing major clients in the first year."
        ]
      },
      {
        title: "Senior Product Owner",
        company: "Financial Services Company",
        location: "City",
        period: "2022 - 2023",
        description: [
          "Led multiple product teams managing client-facing services.",
          "Launched user management portal reducing support tickets by 50% and implemented payment approval system generating significant revenue.",
        ]
      },
      {
        title: "Product Lead",
        company: "Major Bank",
        location: "City",
        period: "2018 - 2022",
        description: [
          "Led implementation of major payment infrastructure project and managed payment validation engine rebuild. Launched first B2B account aggregation solution using Open Banking APIs, successfully scaling from pilot to full regional launch."
        ]
      },
      {
        title: "Head of Growth",
        company: "E-commerce Platform",
        location: "City",
        period: "2018",
        description: [
          "B2B e-commerce platform for wholesale fashion & lifestyle.",
          "Led growth strategy & execution, exceeding lead generation targets by 60% and implementing comprehensive growth strategy."
        ]
      },
      {
        title: "Growth Consultant",
        company: "Startup Incubator",
        location: "City",
        period: "2017",
        description: [
          "Startup incubator and fintech portfolio company.",
          "Consulted for portfolio companies on growth strategy & execution, helping scale customer acquisition rate by 25% through new channels."
        ]
      },
      {
        title: "Marketing Manager",
        company: "Mobility Startup",
        location: "City",
        period: "2016 - 2017",
        description: [
          "Early-stage companies in mobility and transportation sector.",
          "Managed marketing and product development, successfully launching brand and acquiring thousands of early users."
        ]
      },
      {
        title: "Head of Client Services & Analytics",
        company: "Various Digital Companies",
        location: "City",
        period: "2010 - 2016",
        description: [
          "Worked in digital advertising, focusing on client services and analytics products development at various technology and advertising companies."
        ]
      }
    ],
    education: [
      {
        degree: "MSc Technology Management",
        institution: "University A",
        period: "2013 - 2014"
      },
      {
        degree: "BSc Computer Science",
        institution: "University B",
        period: "2007 - 2010"
      }
    ],
    skills: {
      technical: [
        "Web development (NodeJS, VueJS, ExpressJS, MongoDB, SQL, basic devops CI/CD)",
        "Strong understanding of web analytics technology and visual analytics tools"
      ],
      other: [
        "Software Development",
        "Project Management",
        "Team Leadership"
      ]
    },
    competencySections: [
      {
        id: 'technical-skills',
        title: 'Technical Skills',
        type: 'list',
        content: [
          "Web development (NodeJS, VueJS, ExpressJS, MongoDB, SQL, basic devops CI/CD)",
          "Strong understanding of web analytics technology and visual analytics tools"
        ],
        visible: true,
        displayInline: true
      },
      {
        id: 'other-skills',
        title: 'Other Skills',
        type: 'list',
        content: [
          "Software Development",
          "Project Management",
          "Team Leadership"
        ],
        visible: true,
        displayInline: true
      },
      {
        id: 'languages',
        title: 'Languages',
        type: 'list',
        content: [
          "Language A (native)",
          "Language B (native)",
          "Language C (limited working proficiency)"
        ],
        visible: true,
        displayInline: false
      }
    ],
    sectionTitles: {
      personalInfo: "Personal Information",
      experience: "Experience",
      education: "Education",
      competencies: "Other Competencies"
    },
    visibility: {
      personalInfo: true,
      experience: true,
      education: true,
      competencySections: true
    }
}

export const defaultCV = cv 