import { Button } from "@/components/ui/button"
import { PrintButton } from '@/components/PrintButton'
import { FileText, Save, RotateCcw, LogOut, LogIn } from 'lucide-react'
import { useAuth } from "@/contexts/AuthContext"

interface NavbarProps {
  showEditor: boolean
  onToggleEditor: () => void
  onSave: () => void
  onReset: () => void
  hasUnsavedChanges: boolean
  onSignInClick?: () => void
}

export function Navbar({ 
  showEditor, 
  onToggleEditor, 
  onSave, 
  onReset, 
  hasUnsavedChanges,
  onSignInClick 
}: NavbarProps) {
  const { user, signOut } = useAuth()

  return (
    <div className="fixed top-0 left-0 right-0 z-20 bg-background border-b shadow-sm">
      <div className="container mx-auto">
        <div className="toolbar flex justify-between items-center h-12 px-4">
          <div className="flex items-center gap-1.5">
            <div className="bg-[#646cff] p-1.5 rounded-lg">
              <FileText className="h-4 w-4 text-white" />
            </div>
            <span className="text-sm text-primary font-semilight tracking-wide hidden md:inline">Simple CV Template</span>
          </div>
          
          <div className="flex items-center gap-3">
            {/* Desktop Navigation */}
            <div className="hidden md:flex items-center gap-3">
              <Button 
                variant="ghost"
                size="sm"
                onClick={onToggleEditor}
                className="print:hidden text-xs"
              >
                {showEditor ? 'Hide Editor' : 'Show Editor'}
              </Button>
              <Button
                variant="ghost"
                size="sm"
                onClick={onReset}
                className="print:hidden text-xs"
                disabled={!hasUnsavedChanges}
              >
                <RotateCcw className="h-4 w-4 mr-1" />
                Reset
              </Button>
              <Button
                variant={hasUnsavedChanges ? "default" : "ghost"}
                size="sm"
                onClick={onSave}
                className="print:hidden text-xs"
              >
                <Save className="h-4 w-4 mr-1" />
                Save{hasUnsavedChanges ? '*' : ''}
              </Button>
              <PrintButton variant="default" />
              {user ? (
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={signOut}
                  className="print:hidden text-xs"
                >
                  <LogOut className="h-4 w-4 mr-1" />
                  Sign Out
                </Button>
              ) : (
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={onSignInClick}
                  className="print:hidden text-xs"
                >
                  <LogIn className="h-4 w-4 mr-1" />
                  Sign In
                </Button>
              )}
            </div>

            {/* Mobile Navigation */}
            <div className="md:hidden flex items-center gap-2">
              <Button
                variant="ghost"
                size="icon"
                onClick={onReset}
                className="print:hidden h-8 w-8"
                disabled={!hasUnsavedChanges}
              >
                <RotateCcw className="h-4 w-4" />
              </Button>
              <Button
                variant={hasUnsavedChanges ? "default" : "ghost"}
                size="icon"
                onClick={onSave}
                className="print:hidden h-8 w-8"
              >
                <Save className="h-4 w-4" />
              </Button>
              <PrintButton variant="ghost" className="h-8 w-8 p-0" />
              {user ? (
                <Button
                  variant="ghost"
                  size="icon"
                  onClick={signOut}
                  className="print:hidden h-8 w-8"
                >
                  <LogOut className="h-4 w-4" />
                </Button>
              ) : (
                <Button
                  variant="ghost"
                  size="icon"
                  onClick={onSignInClick}
                  className="print:hidden h-8 w-8"
                >
                  <LogIn className="h-4 w-4" />
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
} 