export async function improveText(text: string, canUseAI: boolean): Promise<string> {
  if (!canUseAI) {
    throw new Error('AI features are only available with a pro subscription');
  }

  try {
    const response = await fetch('/api/improve', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ text }),
    });

    if (!response.ok) {
      const errorData = await response.json().catch(() => null);
      console.error('Text Improvement Error Response:', {
        status: response.status,
        statusText: response.statusText,
        error: errorData
      });
      throw new Error(`Failed to improve text: ${response.statusText}`);
    }

    const result = await response.json();
    return result.text;
  } catch (error) {
    console.error('Text Improvement Service Error:', error);
    throw error;
  }
} 