import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { FileText, Palette, Download, Sparkles, Target, Upload, Save } from "lucide-react";
import { useNavigate, Link } from "react-router-dom";
import { CVPreview } from "@/components/cv/CVPreview";

export default function LandingPage() {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-[#FAFAFA] text-[#333] selection:bg-[#007FFF]/10">
      {/* Hero Section */}
      <section className="container mx-auto px-4 py-24">
        <div className="max-w-[1200px] mx-auto grid md:grid-cols-2 gap-12 items-center min-h-[calc(100vh-200px)]">
          {/* Hero Content */}
          <div className="text-left md:text-left text-center">
            <h1 className="text-4xl md:text-5xl font-medium tracking-tight leading-[1.2] text-[#1A1A1A]">
              The smart CV builder that{" "}
              <span className="text-[#007FFF]">gets you interviews</span>
            </h1>
            <p className="mt-6 text-lg text-[#666] leading-relaxed">
              Stop wrestling with formatting. Our intelligent CV builder handles the design while you focus on what matters - telling your professional story.
            </p>
            <div className="flex flex-col items-center md:items-start">
              <Button 
                size="lg" 
                className="mt-10 bg-[#007FFF] hover:bg-[#0066CC] text-white px-8 py-6 rounded-lg text-base font-normal transition-all duration-200 shadow-[0_4px_6px_rgba(0,127,255,0.12)] hover:shadow-[0_6px_8px_rgba(0,127,255,0.16)] hover:translate-y-[-1px]"
                onClick={() => navigate("/editor")}
              >
                Create your CV now
              </Button>
              <p className="mt-2 text-xs text-[#666]">Free forever. No sign-up needed.</p>
            </div>
          </div>

          {/* CV Preview */}
          <div className="text-left">
            <div className="transform scale-[0.85] origin-top transition-transform duration-300">
              <div className="bg-white border border-[#E5E7EB] shadow-sm">
                <CVPreview />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section className="container mx-auto px-4 py-24 bg-white border-y border-[#EAEAEA]">
        <div className="max-w-[800px] mx-auto">
          <h2 className="text-2xl font-medium text-center mb-3 leading-tight text-[#1A1A1A]">
            Land your next role faster
          </h2>
          <p className="text-center text-[#666] mb-14 text-base">
            Everything you need to tell your professional story effectively
          </p>
          
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <Card className="p-7 bg-white border border-[#EAEAEA] rounded-xl shadow-[0_2px_4px_rgba(0,0,0,0.04)] hover:shadow-[0_4px_8px_rgba(0,0,0,0.08)] transition-all duration-200">
              <FileText className="w-8 h-8 text-[#007FFF] mb-5" />
              <h3 className="text-lg font-medium mb-2 text-[#1A1A1A]">Professional Templates</h3>
              <p className="text-[#666] leading-relaxed text-sm">
                Stop worrying about design. Our ATS-friendly templates are proven to get through to hiring managers.
              </p>
            </Card>
            <Card className="p-7 bg-white border border-[#EAEAEA] rounded-xl shadow-[0_2px_4px_rgba(0,0,0,0.04)] hover:shadow-[0_4px_8px_rgba(0,0,0,0.08)] transition-all duration-200">
              <Palette className="w-8 h-8 text-[#007FFF] mb-5" />
              <h3 className="text-lg font-medium mb-2 text-[#1A1A1A]">Focus on Content</h3>
              <p className="text-[#666] leading-relaxed text-sm">
                Our smart editor handles the formatting while you focus on what matters - showcasing your achievements.
              </p>
            </Card>
            <Card className="p-7 bg-white border border-[#EAEAEA] rounded-xl shadow-[0_2px_4px_rgba(0,0,0,0.04)] hover:shadow-[0_4px_8px_rgba(0,0,0,0.08)] transition-all duration-200">
              <Download className="w-8 h-8 text-[#007FFF] mb-5" />
              <h3 className="text-lg font-medium mb-2 text-[#1A1A1A]">PDF Export</h3>
              <p className="text-[#666] leading-relaxed text-sm">
                Export your CV to a professional PDF format with one click.
              </p>
            </Card>
            <Card className="p-7 bg-white border border-[#EAEAEA] rounded-xl shadow-[0_2px_4px_rgba(0,0,0,0.04)] hover:shadow-[0_4px_8px_rgba(0,0,0,0.08)] transition-all duration-200">
              <div className="flex items-center justify-between mb-5">
                <FileText className="w-8 h-8 text-[#007FFF]" />
                <span className="text-xs px-2 py-0.5 bg-[#007FFF]/10 text-[#007FFF] rounded">PRO</span>
              </div>
              <h3 className="text-lg font-medium mb-2 text-[#1A1A1A]">AI Enhancement</h3>
              <p className="text-[#666] leading-relaxed text-sm">
                AI-powered suggestions to make your achievements sound more impactful to employers.
              </p>
            </Card>
            <Card className="p-7 bg-white border border-[#EAEAEA] rounded-xl shadow-[0_2px_4px_rgba(0,0,0,0.04)] hover:shadow-[0_4px_8px_rgba(0,0,0,0.08)] transition-all duration-200">
              <div className="flex items-center justify-between mb-5">
                <Palette className="w-8 h-8 text-[#007FFF]" />
                <span className="text-xs px-2 py-0.5 bg-[#007FFF]/10 text-[#007FFF] rounded">PRO</span>
              </div>
              <h3 className="text-lg font-medium mb-2 text-[#1A1A1A]">Job Spec Matching</h3>
              <p className="text-[#666] leading-relaxed text-sm">
                Get personalized suggestions to align your CV with job requirements and boost interview chances.
              </p>
            </Card>
            <Card className="p-7 bg-white border border-[#EAEAEA] rounded-xl shadow-[0_2px_4px_rgba(0,0,0,0.04)] hover:shadow-[0_4px_8px_rgba(0,0,0,0.08)] transition-all duration-200">
              <div className="flex items-center justify-between mb-5">
                <Download className="w-8 h-8 text-[#007FFF]" />
                <span className="text-xs px-2 py-0.5 bg-[#007FFF]/10 text-[#007FFF] rounded">PRO</span>
              </div>
              <h3 className="text-lg font-medium mb-2 text-[#1A1A1A]">Smart Import</h3>
              <p className="text-[#666] leading-relaxed text-sm">
                Import your existing CV from LinkedIn, PDF, or DOCX to get started quickly.
              </p>
            </Card>
          </div>
        </div>
      </section>

      {/* How It Works Section */}
      <section className="container mx-auto px-4 py-24">
        <div className="max-w-[800px] mx-auto">
          <h2 className="text-2xl font-medium text-center mb-12 leading-tight text-[#1A1A1A]">
            3 Steps to More Interview Invites
          </h2>
          <Accordion type="single" collapsible className="w-full space-y-3">
            <AccordionItem value="step-1" className="border border-[#EAEAEA] rounded-lg px-6 shadow-[0_1px_2px_rgba(0,0,0,0.04)]">
              <AccordionTrigger className="text-base font-medium py-4 hover:text-[#007FFF] transition-colors duration-200">
                Step 1: Import your existing CV or start fresh
              </AccordionTrigger>
              <AccordionContent className="text-[#666] pb-6 leading-relaxed text-sm">
                Import your existing CV or start fresh. Our smart editor formats everything automatically - 
                just focus on telling your story.
              </AccordionContent>
            </AccordionItem>
            <AccordionItem value="step-2" className="border border-[#EAEAEA] rounded-lg px-6 shadow-[0_1px_2px_rgba(0,0,0,0.04)]">
              <AccordionTrigger className="text-base font-medium py-4 hover:text-[#007FFF] transition-colors duration-200">
                Step 2: Highlight the achievements that matter most
              </AccordionTrigger>
              <AccordionContent className="text-[#666] pb-6 leading-relaxed text-sm">
                Our AI helps you highlight the achievements that matter most to employers. 
                No more guessing what to include or how to phrase it.
              </AccordionContent>
            </AccordionItem>
            <AccordionItem value="step-3" className="border border-[#EAEAEA] rounded-lg px-6 shadow-[0_1px_2px_rgba(0,0,0,0.04)]">
              <AccordionTrigger className="text-base font-medium py-4 hover:text-[#007FFF] transition-colors duration-200">
                Step 3: Download and apply
              </AccordionTrigger>
              <AccordionContent className="text-[#666] pb-6 leading-relaxed text-sm">
                Download your professionally formatted CV and start applying. Our users report up to 3x more 
                interview invites with their new CV.
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        </div>
      </section>

      {/* Pricing Section */}
      <section className="container mx-auto px-4 py-24 bg-white border-y border-[#EAEAEA]">
        <div className="max-w-[800px] mx-auto">
          <h2 className="text-2xl font-medium text-center mb-3 leading-tight text-[#1A1A1A]">
            Simple, Transparent Pricing
          </h2>
          <p className="text-center text-[#666] mb-14 text-base">
            Choose the plan that works for you
          </p>

          <div className="grid md:grid-cols-2 gap-8 max-w-[700px] mx-auto">
            {/* Free Plan */}
            <Card className="p-8 bg-white border border-[#EAEAEA] rounded-xl shadow-[0_2px_4px_rgba(0,0,0,0.04)] hover:shadow-[0_4px_8px_rgba(0,0,0,0.08)] transition-all duration-200">
              <div className="mb-6">
                <h3 className="text-xl font-medium mb-2 text-[#1A1A1A]">Free</h3>
                <p className="text-[#666] text-sm mb-4">Everything you need to create a professional CV</p>
                <div className="flex items-baseline gap-1 mb-4">
                  <span className="text-3xl font-medium text-[#1A1A1A]">$0</span>
                  <span className="text-[#666]">/forever</span>
                </div>
              </div>

              <ul className="space-y-3 mb-8">
                <li className="flex items-center gap-2 text-sm text-[#666]">
                  <div className="w-5 h-5 rounded-full bg-[#007FFF]/10 flex items-center justify-center">
                    <FileText className="w-3 h-3 text-[#007FFF]" />
                  </div>
                  Professional CV templates
                </li>
                <li className="flex items-center gap-2 text-sm text-[#666]">
                  <div className="w-5 h-5 rounded-full bg-[#007FFF]/10 flex items-center justify-center">
                    <Palette className="w-3 h-3 text-[#007FFF]" />
                  </div>
                  Smart formatting
                </li>
                <li className="flex items-center gap-2 text-sm text-[#666]">
                  <div className="w-5 h-5 rounded-full bg-[#007FFF]/10 flex items-center justify-center">
                    <Download className="w-3 h-3 text-[#007FFF]" />
                  </div>
                  PDF export
                </li>
              </ul>

              <Button 
                className="w-full bg-[#007FFF] hover:bg-[#0066CC] text-white"
                onClick={() => navigate("/editor")}
              >
                Get Started
              </Button>
            </Card>

            {/* Pro Plan */}
            <Card className="p-8 bg-white border-2 border-[#007FFF] rounded-xl shadow-[0_2px_4px_rgba(0,0,0,0.04)] hover:shadow-[0_4px_8px_rgba(0,0,0,0.08)] transition-all duration-200 relative">
              <div className="absolute -top-3 right-8 px-3 py-1 bg-[#007FFF] text-white text-xs rounded-full">
                Popular
              </div>
              <div className="mb-6">
                <h3 className="text-xl font-medium mb-2 text-[#1A1A1A]">Pro</h3>
                <p className="text-[#666] text-sm mb-4">AI-powered features to maximize your success</p>
                <div className="flex items-baseline gap-1 mb-4">
                  <span className="text-3xl font-medium text-[#1A1A1A]">$10</span>
                  <span className="text-[#666]">/month</span>
                </div>
              </div>

              <ul className="space-y-3 mb-8">
                <li className="flex items-center gap-2 text-sm text-[#666]">
                  <div className="w-5 h-5 rounded-full bg-[#007FFF]/10 flex items-center justify-center">
                    <Sparkles className="w-3 h-3 text-[#007FFF]" />
                  </div>
                  Everything in Free
                </li>
                <li className="flex items-center gap-2 text-sm text-[#666]">
                  <div className="w-5 h-5 rounded-full bg-[#007FFF]/10 flex items-center justify-center">
                    <Target className="w-3 h-3 text-[#007FFF]" />
                  </div>
                  AI content enhancement
                </li>
                <li className="flex items-center gap-2 text-sm text-[#666]">
                  <div className="w-5 h-5 rounded-full bg-[#007FFF]/10 flex items-center justify-center">
                    <FileText className="w-3 h-3 text-[#007FFF]" />
                  </div>
                  Job spec matching
                </li>
                <li className="flex items-center gap-2 text-sm text-[#666]">
                  <div className="w-5 h-5 rounded-full bg-[#007FFF]/10 flex items-center justify-center">
                    <Upload className="w-3 h-3 text-[#007FFF]" />
                  </div>
                  Smart CV import
                </li>
                <li className="flex items-center gap-2 text-sm text-[#666]">
                  <div className="w-5 h-5 rounded-full bg-[#007FFF]/10 flex items-center justify-center">
                    <Save className="w-3 h-3 text-[#007FFF]" />
                  </div>
                  Save your CV
                </li>
              </ul>

              <Button 
                className="w-full bg-[#007FFF] hover:bg-[#0066CC] text-white"
                onClick={() => navigate("/upgrade")}
              >
                Upgrade to Pro
              </Button>
            </Card>
          </div>
        </div>
      </section>

      {/* Testimonials Section */}
      <section className="container mx-auto px-4 py-24 bg-white border-y border-[#EAEAEA]">
        <div className="max-w-[800px] mx-auto">
          <h2 className="text-2xl font-medium text-center mb-12 leading-tight text-[#1A1A1A]">
            Success Stories from Hired Professionals
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <Card className="p-7 bg-white border border-[#EAEAEA] rounded-xl shadow-[0_2px_4px_rgba(0,0,0,0.04)]">
              <p className="text-base italic text-[#666] mb-5 leading-relaxed">
                "I got 3 interview calls within a week of using my new CV!"
              </p>
              <p className="font-medium text-[#1A1A1A]">Sarah</p>
              <p className="text-xs text-[#888]">Marketing Specialist</p>
            </Card>
            <Card className="p-7 bg-white border border-[#EAEAEA] rounded-xl shadow-[0_2px_4px_rgba(0,0,0,0.04)]">
              <p className="text-base italic text-[#666] mb-5 leading-relaxed">
                "The clean design helped me stand out. Landed my dream tech role in 2 weeks."
              </p>
              <p className="font-medium text-[#1A1A1A]">Michael</p>
              <p className="text-xs text-[#888]">Software Engineer</p>
            </Card>
            <Card className="p-7 bg-white border border-[#EAEAEA] rounded-xl shadow-[0_2px_4px_rgba(0,0,0,0.04)]">
              <p className="text-base italic text-[#666] mb-5 leading-relaxed">
                "Doubled my interview response rate with this CV format."
              </p>
              <p className="font-medium text-[#1A1A1A]">Emma</p>
              <p className="text-xs text-[#888]">Product Manager</p>
            </Card>
          </div>
        </div>
      </section>

      {/* Final CTA Section */}
      <section className="container mx-auto px-4 py-24 text-center max-w-[800px]">
        <h2 className="text-2xl font-medium mb-6 leading-tight text-[#1A1A1A]">
          Stop Worrying About Formatting. Start Getting Interviews.
        </h2>
        <Button 
          size="lg"
          className="bg-[#007FFF] hover:bg-[#0066CC] text-white px-8 py-6 rounded-lg text-base font-normal transition-all duration-200 shadow-[0_4px_6px_rgba(0,127,255,0.12)] hover:shadow-[0_6px_8px_rgba(0,127,255,0.16)] hover:translate-y-[-1px]"
          onClick={() => navigate("/editor")}
        >
          Get started for free
        </Button>
        <p className="mt-2 text-xs text-[#666]">Free forever. No sign-up needed.</p>
      </section>

      {/* Footer */}
      <footer className="container mx-auto px-4 py-6 border-t border-[#EAEAEA]">
        <div className="max-w-[800px] mx-auto flex flex-col md:flex-row justify-between items-center gap-4">
          <div className="flex gap-6">
            <Link to="/" className="text-sm text-[#666] hover:text-[#333] transition-colors duration-200">
              Home
            </Link>
            <Link to="/about" className="text-sm text-[#666] hover:text-[#333] transition-colors duration-200">
              About
            </Link>
            <Link to="/privacy" className="text-sm text-[#666] hover:text-[#333] transition-colors duration-200">
              Privacy Policy
            </Link>
            <Link to="/support" className="text-sm text-[#666] hover:text-[#333] transition-colors duration-200">
              Support
            </Link>
          </div>
          <p className="text-xs text-[#888]">
            Built with ❤️ for job seekers
          </p>
        </div>
      </footer>
    </div>
  );
} 