interface SectionProps {
  title: string
  children: React.ReactNode
}

export function Section({ title, children }: SectionProps) {
  return (
    <section className="space-y-3 md:space-y-4">
      <h2 className="text-lg md:text-xl font-semibold tracking-tight border-b border-border pb-1.5 md:pb-2 text-foreground">
        {title}
      </h2>
      {children}
    </section>
  )
} 