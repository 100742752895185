import { Contact } from "@/types/cv"
import { Dot } from "lucide-react"
import { Avatar, AvatarImage, AvatarFallback } from "@/components/ui/avatar"
import { cn } from "@/lib/utils"

interface HeaderProps {
  name: string
  contact: Contact
}

export function Header({ name, contact }: HeaderProps) {
  return (
    <header className="space-y-2 md:space-y-4">
      <div className="flex items-center gap-3 md:gap-4">
        {contact.picture?.visible && (
          <Avatar className={cn(
            "h-12 w-12 md:h-16 md:w-16",
            !contact.picture.round && "rounded-sm"
          )}>
            <AvatarImage src={contact.picture.url} alt={name} />
            <AvatarFallback>{name.split(' ').map(n => n[0]).join('')}</AvatarFallback>
          </Avatar>
        )}
        <h1 className="text-2xl md:text-4xl font-bold tracking-tight text-foreground">{name}</h1>
      </div>
      <div className="flex flex-col md:flex-row md:items-center gap-1 md:gap-2 text-sm text-muted-foreground">
        <span>{contact.email}</span>
        <Dot className="hidden md:block h-4 w-4" />
        <span>{contact.phone}</span>
        <Dot className="hidden md:block h-4 w-4" />
        <span>{contact.address}</span>
      </div>
    </header>
  )
} 