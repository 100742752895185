import { CV } from '@/types/cv'
import { Header } from '@/components/cv/header'
import { Experience } from '@/components/cv/experience'
import { Education } from '@/components/cv/education'
import { CompetencySections } from '@/components/cv/competency-section'
import { Section } from '@/components/cv/section'

interface CVViewerProps {
  data: CV
}

export function CVViewer({ data }: CVViewerProps) {
  return (
    <div className="max-w-4xl mx-auto space-y-6 md:space-y-8 p-4 md:p-6">
      {data.visibility.personalInfo && (
        <div className="personal-info">
          <Header name={data.name} contact={data.contact} />
        </div>
      )}

      {data.visibility.experience && (
        <div className="experience mt-6 md:mt-8">
          <Section title={data.sectionTitles.experience}>
            <Experience experience={data.experience} />
          </Section>
        </div>
      )}

      {data.visibility.education && (
        <div className="education mt-6 md:mt-8">
          <Section title={data.sectionTitles.education}>
            <Education education={data.education} />
          </Section>
        </div>
      )}

      {data.visibility.competencySections && (
        <div className="competencies mt-6 md:mt-8">
          <CompetencySections 
            sections={data.competencySections} 
            title={data.sectionTitles.competencies}
          />
        </div>
      )}
    </div>
  )
} 