import { Education as EducationType } from "@/types/cv"

interface EducationProps {
  education: EducationType[]
}

export function Education({ education }: EducationProps) {
  return (
    <div className="space-y-3 md:space-y-4">
      {education.map((edu, index) => (
        <div key={index} className="flex flex-col md:flex-row md:justify-between md:items-start gap-0.5 md:gap-0">
          <div>
            <h3 className="font-semibold text-foreground">{edu.degree}</h3>
            <p className="text-sm text-muted-foreground">{edu.institution}</p>
          </div>
          <span className="text-sm text-muted-foreground order-first md:order-none">{edu.period}</span>
        </div>
      ))}
    </div>
  )
} 