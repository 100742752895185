import { createContext, useContext, useEffect, useState } from 'react'
import { User } from '@supabase/supabase-js'
import { supabase } from '@/lib/supabase'

interface AuthContextType {
  user: User | null
  isPro: boolean
  signOut: () => Promise<void>
}

const AuthContext = createContext<AuthContextType | undefined>(undefined)

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useState<User | null>(null)
  const [isPro, setIsPro] = useState(false)

  const checkProStatus = async (userId: string) => {
    const { data, error } = await supabase
      .from('profiles')
      .select('is_pro')
      .eq('id', userId)
      .single()
    
    if (error) {
      console.error('Error fetching pro status:', error)
      return false
    }

    return data?.is_pro ?? false
  }

  useEffect(() => {
    // Check active sessions and sets the user
    supabase.auth.getSession().then(async ({ data: { session } }) => {
      setUser(session?.user ?? null)
      if (session?.user) {
        const userIsPro = await checkProStatus(session.user.id)
        setIsPro(userIsPro)
      }
    })

    // Listen for changes on auth state
    const { data: { subscription: authSubscription } } = supabase.auth.onAuthStateChange(async (_event, session) => {
      setUser(session?.user ?? null)
      if (session?.user) {
        const userIsPro = await checkProStatus(session.user.id)
        setIsPro(userIsPro)
      } else {
        setIsPro(false)
      }
    })

    return () => authSubscription.unsubscribe()
  }, [])

  // Subscribe to profile changes
  useEffect(() => {
    if (!user) return

    const profileSubscription = supabase
      .channel('profile-changes')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'profiles',
          filter: `id=eq.${user.id}`,
        },
        async (payload) => {
          if (payload.eventType === 'UPDATE') {
            // Update pro status when profile changes
            setIsPro(payload.new.is_pro ?? false)
          }
        }
      )
      .subscribe()

    return () => {
      supabase.removeChannel(profileSubscription)
    }
  }, [user])

  const signOut = async () => {
    await supabase.auth.signOut()
  }

  return (
    <AuthContext.Provider value={{ user, isPro, signOut }}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider')
  }
  return context
} 