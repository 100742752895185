export function CVPreview() {
  return (
    <div className="max-w-4xl mx-auto space-y-4 md:space-y-6 p-4 md:p-6 scale-90">
      {/* Header */}
      <div>
        <h2 className="text-xl font-semibold text-[#1A1A1A] mb-2">Sarah Anderson</h2>
        <div className="flex flex-wrap gap-x-4 gap-y-2 text-[11px] text-[#666]">
          <span>Oakland, CA</span>
          <span className="text-[#999]">•</span>
          <span>sarah.anderson@example.com</span>
          <span className="text-[#999]">•</span>
          <span>(510) 555-0123</span>
        </div>
      </div>

      {/* Experience Section */}
      <div className="space-y-4">
        <h3 className="text-base font-semibold text-[#1A1A1A] pb-1 border-b border-[#E5E7EB]">
          Experience
        </h3>
        <div className="space-y-4">
          <div>
            <div className="flex justify-between items-start mb-1">
              <div>
                <h4 className="text-sm font-medium text-[#1A1A1A] leading-tight">Senior Frontend Engineer</h4>
                <p className="text-[11px] text-[#666] mt-0.5">Stripe</p>
              </div>
              <p className="text-[11px] text-[#666] whitespace-nowrap">May 2020 - Present</p>
            </div>
            <p className="text-[11px] text-[#666] leading-relaxed mt-2">
              Led Stripe Dashboard redesign with 40% engagement boost. Built component library cutting dev time by 60%.
            </p>
          </div>
          <div>
            <div className="flex justify-between items-start mb-1">
              <div>
                <h4 className="text-sm font-medium text-[#1A1A1A] leading-tight">Frontend Developer</h4>
                <p className="text-[11px] text-[#666] mt-0.5">Airbnb</p>
              </div>
              <p className="text-[11px] text-[#666] whitespace-nowrap">March 2018 - April 2020</p>
            </div>
            <p className="text-[11px] text-[#666] leading-relaxed mt-2">
              Optimized search performance by 35%. Improved booking flow conversion rate by 25%.
            </p>
          </div>
          <div>
            <div className="flex justify-between items-start mb-1">
              <div>
                <h4 className="text-sm font-medium text-[#1A1A1A] leading-tight">Junior Web Developer</h4>
                <p className="text-[11px] text-[#666] mt-0.5">Reddit</p>
              </div>
              <p className="text-[11px] text-[#666] whitespace-nowrap">June 2016 - February 2018</p>
            </div>
            <p className="text-[11px] text-[#666] leading-relaxed mt-2">
              Developed and maintained key features for Reddit's web client. Reduced load time by 20% through code optimization.
            </p>
          </div>
        </div>
      </div>

      {/* Education Section */}
      <div className="space-y-4">
        <h3 className="text-base font-semibold text-[#1A1A1A] pb-1 border-b border-[#E5E7EB]">
          Education
        </h3>
        <div className="space-y-4">
          <div>
            <div className="flex justify-between items-start mb-1">
              <div>
                <h4 className="text-sm font-medium text-[#1A1A1A] leading-tight">BSc Computer Science</h4>
                <p className="text-[11px] text-[#666] mt-0.5">UC Berkeley</p>
              </div>
              <p className="text-[11px] text-[#666] whitespace-nowrap">Sept 2014 - May 2018</p>
            </div>
            <p className="text-[11px] text-[#666] leading-relaxed mt-2">
              Graduated with High Honors. TA for Data Structures & Web Dev.
            </p>
          </div>
        </div>
      </div>

      {/* Skills Section */}
      <div className="space-y-4">
        <h3 className="text-base font-semibold text-[#1A1A1A] pb-1 border-b border-[#E5E7EB]">
          Skills
        </h3>
        <p className="text-[11px] text-[#666] leading-relaxed">
          {["React", "TypeScript", "Next.js", "GraphQL", "Node.js", "Jest", "Webpack", "AWS"].join(" • ")}
        </p>
      </div>
    </div>
  );
} 