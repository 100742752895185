import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist';
import type { PDFDocumentProxy } from 'pdfjs-dist/types/src/display/api';

// Use a reliable CDN that's specifically designed for PDF.js
GlobalWorkerOptions.workerSrc = 'https://unpkg.com/pdfjs-dist@latest/build/pdf.worker.min.js';

export const getPdfDocument = async (file: ArrayBuffer): Promise<PDFDocumentProxy> => {
  try {
    return await getDocument(file).promise;
  } catch (error) {
    console.error('Error loading PDF document:', error);
    throw new Error('Failed to load PDF document');
  }
}; 