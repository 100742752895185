import { useEffect, useState, useCallback } from 'react'
import { BrowserRouter, Routes, Route, Navigate, useLocation } from 'react-router-dom'
import { CVEditor } from '@/components/CVEditor'
import { CVViewer } from '@/components/CVViewer'
import { Navbar } from '@/components/Navbar'
import { defaultCV } from '@/data/cv'
import { CV } from '@/types/cv'
import { AuthProvider, useAuth } from '@/contexts/AuthContext'
import { Login } from '@/components/Login'
import LandingPage from '@/components/LandingPage'
import { supabase } from '@/lib/supabase'
import { useToast } from "@/hooks/use-toast"
import { Toaster } from "@/components/ui/toaster"
import { Button } from "@/components/ui/button"
import { FileText, Pencil } from 'lucide-react'
import { RealtimePostgresChangesPayload } from '@supabase/supabase-js'
import { LoginDialog } from '@/components/LoginDialog'

// Auth Route Component (for login page)
function AuthRoute({ children }: { children: React.ReactNode }) {
  const { user } = useAuth()
  const location = useLocation()
  const from = location.state?.from?.pathname || '/editor'
  
  if (user) {
    return <Navigate to={from} replace />
  }
  
  return <>{children}</>
}

function CVBuilder() {
  const { user } = useAuth()
  const { toast } = useToast()
  const [showEditor, setShowEditor] = useState(true)
  const [cvData, setCVData] = useState<CV>(defaultCV)
  const [savedData, setSavedData] = useState<CV | null>(null)
  const [isSaving, setIsSaving] = useState(false)
  const [showLoginDialog, setShowLoginDialog] = useState(false)
  const hasUnsavedChanges = JSON.stringify(cvData) !== JSON.stringify(savedData)

  const loadCV = useCallback(async () => {
    if (!user) return
    
    try {
      const { data, error } = await supabase
        .from('cvs')
        .select('cv_data')
        .eq('user_id', user.id)
        .single()

      if (error && error.code !== 'PGRST116') throw error

      if (data?.cv_data) {
        setCVData(data.cv_data)
        setSavedData(data.cv_data)
      } else {
        // If no CV exists, create one with current data
        const { error: insertError } = await supabase
          .from('cvs')
          .insert([
            { user_id: user.id, cv_data: cvData }
          ])
        
        if (insertError) throw insertError
        setSavedData(cvData)
      }
    } catch (error) {
      console.error('Error loading CV:', error)
      toast({
        title: "Error loading CV",
        description: "There was a problem loading your CV. Please try again.",
        variant: "destructive",
      })
    }
  }, [user, toast, cvData])

  // Load data when user changes
  useEffect(() => {
    if (user) {
      loadCV()
    } else {
      setSavedData(null)
    }
  }, [user, loadCV])

  // Set up real-time subscription for logged-in users
  useEffect(() => {
    if (!user) return

    const channel = supabase
      .channel('cv_changes')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'cvs',
          filter: `user_id=eq.${user.id}`
        },
        (payload: RealtimePostgresChangesPayload<{ cv_data: CV }>) => {
          if (payload.eventType === 'UPDATE' && payload.new.cv_data) {
            // Only update if the change wasn't made by this client
            if (!isSaving) {
              const newData = payload.new.cv_data
              setCVData(newData)
              setSavedData(newData)
              toast({
                title: "CV Updated",
                description: "Your CV has been updated from another session"
              })
            }
          }
        }
      )
      .subscribe()

    return () => {
      supabase.removeChannel(channel)
    }
  }, [user, isSaving, toast])

  const saveCV = async () => {
    if (!user) {
      setShowLoginDialog(true)
      return
    }
    
    setIsSaving(true)
    try {
      // First check if a CV exists
      const { data: existingCV } = await supabase
        .from('cvs')
        .select('id')
        .eq('user_id', user.id)
        .single()

      let error;
      if (existingCV) {
        // Update existing CV
        const result = await supabase
          .from('cvs')
          .update({ cv_data: cvData })
          .eq('user_id', user.id)
        error = result.error
      } else {
        // Insert new CV
        const result = await supabase
          .from('cvs')
          .insert({
            user_id: user.id,
            cv_data: cvData
          })
        error = result.error
      }

      if (error) throw error

      setSavedData(cvData)
      toast({
        title: "CV saved",
        description: "Your changes have been saved successfully"
      })
    } catch (error) {
      console.error('Error saving CV:', error)
      toast({
        variant: "destructive",
        title: "Error saving CV",
        description: "Failed to save your changes"
      })
    } finally {
      setIsSaving(false)
    }
  }

  const handleReset = async () => {
    if (!user) {
      setCVData(defaultCV)
      return
    }

    try {
      const { data, error } = await supabase
        .from('cvs')
        .select('cv_data')
        .eq('user_id', user.id)
        .single()

      if (error) throw error

      if (data) {
        setCVData(data.cv_data)
        setSavedData(data.cv_data)
        toast({
          title: "CV reset",
          description: "Your CV has been reset to the last saved version"
        })
      }
    } catch (error) {
      console.error('Error resetting CV:', error)
      toast({
        variant: "destructive",
        title: "Error resetting CV",
        description: "Failed to reset your CV"
      })
    }
  }

  return (
    <div className="min-h-screen bg-muted text-foreground">
      <Navbar 
        showEditor={showEditor}
        onToggleEditor={() => setShowEditor(!showEditor)}
        onSave={saveCV}
        onReset={handleReset}
        hasUnsavedChanges={hasUnsavedChanges}
        onSignInClick={() => setShowLoginDialog(true)}
      />

      <div className="container mx-auto p-4 pt-20">
        <div className={`grid gap-6 ${
          showEditor ? 'grid-cols-1 lg:grid-cols-12' : 'grid-cols-1'
        }`}>
          {showEditor && (
            <div className="editor-column lg:col-span-5 w-full bg-muted/50 md:block">
              <div className="sticky top-20 max-h-[calc(100vh-6rem)] overflow-y-auto p-6">
                <CVEditor 
                  data={cvData} 
                  onChange={setCVData}
                />
              </div>
            </div>
          )}
          <div className={`cv-column ${showEditor ? 'lg:col-span-7 hidden md:block' : ''} w-full ${!showEditor ? 'max-w-3xl mx-auto' : ''}`}>
            <div className="bg-background shadow-sm">
              <CVViewer data={cvData} />
            </div>
          </div>
        </div>
      </div>

      {/* Floating Editor Toggle Button (Mobile Only) */}
      <Button
        variant="default"
        size="icon"
        onClick={() => setShowEditor(!showEditor)}
        className="fixed bottom-6 right-6 h-12 w-12 rounded-full shadow-lg md:hidden print:hidden"
      >
        {showEditor ? <FileText className="h-5 w-5" /> : <Pencil className="h-5 w-5" />}
      </Button>

      <LoginDialog 
        open={showLoginDialog} 
        onOpenChange={setShowLoginDialog}
      />
      <Toaster />
    </div>
  )
}

function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route
            path="/login"
            element={
              <AuthRoute>
                <Login />
              </AuthRoute>
            }
          />
          <Route path="/editor" element={<CVBuilder />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
        <Toaster />
      </BrowserRouter>
    </AuthProvider>
  )
}

export default App
