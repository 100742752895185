import { CV } from "@/types/cv"
import { getPdfDocument } from '@/lib/pdf-config';

interface ParseCVParams {
  type: 'pdf'
  data: string | ArrayBuffer
}

export async function parseCV({ type, data }: ParseCVParams, canUseAI: boolean): Promise<CV> {
  if (!canUseAI) {
    throw new Error('CV parsing is only available with a pro subscription');
  }

  try {
    // If data is a base64 string, convert it to ArrayBuffer
    let pdfData: ArrayBuffer;
    if (typeof data === 'string') {
      const binaryString = window.atob(data.replace(/^data:application\/pdf;base64,/, ''));
      const bytes = new Uint8Array(binaryString.length);
      for (let i = 0; i < binaryString.length; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }
      pdfData = bytes.buffer;
    } else {
      pdfData = data;
    }

    // Load the PDF document first
    await getPdfDocument(pdfData);

    const response = await fetch('/api/parse-cv', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ type, data }),
    });

    if (!response.ok) {
      const errorData = await response.json().catch(() => null);
      console.error('CV Parser Error Response:', {
        status: response.status,
        statusText: response.statusText,
        error: errorData
      });
      throw new Error(`Failed to parse CV: ${response.statusText}`);
    }

    const result = await response.json();
    return result.cv;
  } catch (error) {
    console.error('CV Parser Service Error:', error);
    throw error;
  }
} 