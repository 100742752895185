import { useForm, FieldPath } from "react-hook-form"
import { Button } from "@/components/ui/button"
import { Form } from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import { Textarea } from "@/components/ui/textarea"
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "@/components/ui/collapsible"
import { CV } from "@/types/cv"
import { ChevronDown, Eye, EyeOff, Plus, Trash2, GripVertical, Pencil, Circle, Square, User } from "lucide-react"
import { useState, useEffect, useRef } from "react"
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Checkbox } from "@/components/ui/checkbox"
import { useProFeatures } from "@/config/features";
import { AIButton } from "@/components/ui/ai-button"
import { improveText } from "@/services/ai";
import { Label } from "@/components/ui/label"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog"
import { Upload } from "lucide-react"
import { parseCV } from "@/services/cv-parser"
import { getPdfDocument } from '@/lib/pdf-config';
import { toast } from "@/hooks/use-toast"
import { UpgradeProDialog } from "@/components/ui/upgrade-pro-dialog"

interface CVEditorProps {
  data: CV
  onChange: (data: CV) => void
}

interface SortableItemProps {
  id: string;
  children: React.ReactNode;
}

function SortableItem({ id, children }: SortableItemProps) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? 1 : 0,
  };

  return (
    <div ref={setNodeRef} style={style} className={isDragging ? 'opacity-50' : ''}>
      <div className="flex items-center gap-2">
        <button
          className="touch-none p-2 opacity-40 hover:opacity-100 transition-opacity"
          {...attributes}
          {...listeners}
        >
          <GripVertical className="h-4 w-4" />
        </button>
        {children}
      </div>
    </div>
  );
}

interface ImportDialogProps {
  onImport: (cv: CV) => void
}

function ImportDialog({ onImport }: ImportDialogProps) {
  const { canUseAI } = useProFeatures();
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [open, setOpen] = useState(false)


  // Then update the text extraction function to use this type
  const extractTextFromPDF = async (file: File): Promise<string> => {
    const arrayBuffer = await file.arrayBuffer();
    const pdf = await getPdfDocument(arrayBuffer);
    
    let fullText = '';
    for (let i = 1; i <= pdf.numPages; i++) {
      const page = await pdf.getPage(i);
      const textContent = await page.getTextContent();
      const pageText = textContent.items
        .map((item) => 'str' in item ? item.str : '')
        .join(' ');
      fullText += pageText + '\n';
    }
    
    return fullText;
  };

  const handleImport = async () => {
    if (!canUseAI) return;
    
    try {
      setLoading(true)
      setError(null)
      
      const file = fileInputRef.current?.files?.[0]
      if (!file) {
        setError("Please select a PDF file")
        return
      }

      try {
        const extractedText = await extractTextFromPDF(file);
        const result = await parseCV({ type: 'pdf', data: extractedText }, canUseAI)
        if (result) {
          onImport(result)
          setOpen(false) // Close dialog on success
          toast({
            title: "CV imported successfully",
            description: "Your CV has been parsed and imported.",
          })
        }
      } catch (parseError) {
        console.error('PDF Parsing Error:', parseError);
        setError("Failed to parse PDF. Please check the console for details.")
      }
    } catch (err) {
      console.error('Import Error:', err)
      setError("Failed to import CV. Please try again.")
    } finally {
      setLoading(false)
    }
  }

  const ImportButton = (
    <Button variant="outline" className="gap-2">
      <Upload className="h-4 w-4" />
      Import CV
    </Button>
  )

  if (!canUseAI) {
    return (
      <UpgradeProDialog>
        {ImportButton}
      </UpgradeProDialog>
    )
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        {ImportButton}
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Import CV</DialogTitle>
          <DialogDescription>
            Upload a PDF version of your CV
          </DialogDescription>
        </DialogHeader>
        
        <div className="space-y-6">
          <div className="space-y-2">
            <Label>Upload PDF</Label>
            <div className="flex gap-2">
              <Input
                type="file"
                accept=".pdf"
                ref={fileInputRef}
              />
              <Button 
                onClick={handleImport}
                disabled={loading}
              >
                Import
              </Button>
            </div>
          </div>

          {error && (
            <p className="text-sm text-destructive">{error}</p>
          )}
          
          {loading && (
            <p className="text-sm text-muted-foreground">
              Processing your CV... This may take a few moments.
            </p>
          )}
        </div>
      </DialogContent>
    </Dialog>
  )
}

export function CVEditor({ data, onChange }: CVEditorProps) {
  const { canUseAI } = useProFeatures();
  const [editingSectionTitle, setEditingSectionTitle] = useState<string | null>(null);
  const [editingSubsectionId, setEditingSubsectionId] = useState<string | null>(null);
  const [improvingDescription, setImprovingDescription] = useState<{
    experienceIndex: number;
    descriptionIndex: number;
  } | null>(null);
  const [improvingCompetency, setImprovingCompetency] = useState<{
    sectionIndex: number;
    itemIndex: number;
  } | null>(null);
  
  const form = useForm<CV>({
    defaultValues: data
  });

  useEffect(() => {
    form.reset(data)
  }, [data, form])

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const onSubmit = (values: CV) => {
    onChange(values)
  }

  const addDescription = (experienceIndex: number) => {
    const experience = form.getValues('experience');
    experience[experienceIndex].description.push('');
    form.setValue('experience', experience);
    form.handleSubmit(onSubmit)();
  };

  const addExperience = () => {
    const experience = form.getValues('experience');
    experience.push({
      title: 'New Position',
      company: `Company ${experience.length + 1}`,
      location: 'Location',
      period: 'Present',
      description: ['Add description']
    });
    form.setValue('experience', experience);
    form.handleSubmit(onSubmit)();
  };

  const addEducation = () => {
    const education = form.getValues('education');
    education.push({
      degree: 'New Degree',
      institution: `Institution ${education.length + 1}`,
      period: 'Present'
    });
    form.setValue('education', education);
    form.handleSubmit(onSubmit)();
  };

  const addCompetencySection = () => {
    const sections = form.getValues('competencySections');
    sections.push({
      id: `section-${Date.now()}`,
      title: `Section ${sections.length + 1}`,
      type: 'list',
      content: ['Add item'],
      visible: true,
      displayInline: false
    });
    form.setValue('competencySections', sections);
    form.handleSubmit(onSubmit)();
  };

  const removeExperienceItem = (index: number) => {
    const experience = form.getValues('experience');
    form.setValue('experience', experience.filter((_, i) => i !== index));
    form.handleSubmit(onSubmit)();
  };

  const removeEducationItem = (index: number) => {
    const education = form.getValues('education');
    form.setValue('education', education.filter((_, i) => i !== index));
    form.handleSubmit(onSubmit)();
  };

  const removeCompetencySection = (index: number) => {
    const sections = form.getValues('competencySections');
    form.setValue('competencySections', sections.filter((_, i) => i !== index));
    form.handleSubmit(onSubmit)();
  };

  const removeDescriptionItem = (experienceIndex: number, descriptionIndex: number) => {
    const experience = form.getValues('experience');
    experience[experienceIndex].description = experience[experienceIndex].description.filter((_, i) => i !== descriptionIndex);
    form.setValue('experience', experience);
    form.handleSubmit(onSubmit)();
  };

  const toggleSectionVisibility = (section: keyof CV['visibility']) => {
    const current = form.getValues(`visibility.${section}`);
    form.setValue(`visibility.${section}`, !current);
    form.handleSubmit(onSubmit)();
  };

  const handleExperienceDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (over && active.id !== over.id) {
      const oldIndex = parseInt(active.id.toString().split('-')[1]);
      const newIndex = parseInt(over.id.toString().split('-')[1]);
      const experience = form.getValues('experience');
      form.setValue('experience', arrayMove(experience, oldIndex, newIndex));
      form.handleSubmit(onSubmit)();
    }
  };

  const handleEducationDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (over && active.id !== over.id) {
      const oldIndex = parseInt(active.id.toString().split('-')[1]);
      const newIndex = parseInt(over.id.toString().split('-')[1]);
      const education = form.getValues('education');
      form.setValue('education', arrayMove(education, oldIndex, newIndex));
      form.handleSubmit(onSubmit)();
    }
  };

  const handleCompetencySectionsDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (over && active.id !== over.id) {
      const oldIndex = parseInt(active.id.toString().split('-')[1]);
      const newIndex = parseInt(over.id.toString().split('-')[1]);
      const sections = form.getValues('competencySections');
      form.setValue('competencySections', arrayMove(sections, oldIndex, newIndex));
      form.handleSubmit(onSubmit)();
    }
  };

  const handleSubmitEdit = (value: string, path: FieldPath<CV>, callback: () => void) => {
    form.setValue(path, value);
    form.handleSubmit(onSubmit)();
    callback();
  };

  const InputWithSubmit = ({ 
    defaultValue, 
    onSubmit, 
    onCancel, 
    id, 
    stopPropagation = false,
    className = "w-full h-8",
    placeholder = "",
    isTextArea = false
  }: { 
    defaultValue: string, 
    onSubmit: (value: string) => void, 
    onCancel: () => void, 
    id: string,
    stopPropagation?: boolean,
    className?: string,
    placeholder?: string,
    isTextArea?: boolean
  }) => {
    const [value, setValue] = useState(defaultValue);
    const hasChanged = value !== defaultValue;
    const inputRef = useRef<HTMLInputElement | HTMLTextAreaElement>(null);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setValue(e.target.value);
    };

    const handleSubmit = (e?: React.MouseEvent | React.KeyboardEvent) => {
      if (e) {
        e.preventDefault();
        if (stopPropagation) {
          e.stopPropagation();
        }
      }
      if (hasChanged) {
        onSubmit(value);
        inputRef.current?.focus();
      }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (e.key === 'Enter' && !isTextArea) {
        if (hasChanged) {
          handleSubmit(e);
        }
      }
      if (e.key === 'Enter' && isTextArea && (e.metaKey || e.ctrlKey)) {
        if (hasChanged) {
          handleSubmit(e);
        }
      }
      if (e.key === 'Escape') {
        e.preventDefault();
        setValue(defaultValue);
        onCancel();
      }
    };

    return (
      <div className="flex gap-2">
        {isTextArea ? (
          <Textarea
            ref={inputRef as React.RefObject<HTMLTextAreaElement>}
            value={value}
            onChange={handleChange}
            className={className}
            placeholder={placeholder}
            onKeyDown={handleKeyDown}
            onClick={stopPropagation ? (e) => e.stopPropagation() : undefined}
            id={id}
          />
        ) : (
          <Input
            ref={inputRef as React.RefObject<HTMLInputElement>}
            value={value}
            onChange={handleChange}
            className={className}
            placeholder={placeholder}
            onKeyDown={handleKeyDown}
            onClick={stopPropagation ? (e) => e.stopPropagation() : undefined}
            id={id}
          />
        )}
        {hasChanged && (
          <Button
            type="button"
            variant="default"
            size="sm"
            className="h-8"
            onClick={(e) => handleSubmit(e)}
          >
            Apply
          </Button>
        )}
      </div>
    );
  };

  const improveDescription = async (experienceIndex: number, descriptionIndex: number) => {
    if (!canUseAI) return;
    
    setImprovingDescription({ experienceIndex, descriptionIndex });
    const currentText = form.watch(`experience.${experienceIndex}.description.${descriptionIndex}`);
    
    try {
      const improvedText = await improveText(currentText, canUseAI);
      handleSubmitEdit(
        improvedText, 
        `experience.${experienceIndex}.description.${descriptionIndex}` as FieldPath<CV>, 
        () => {}
      );
    } catch (error) {
      console.error('Failed to improve description:', error);
    } finally {
      setImprovingDescription(null);
    }
  };

  const improveCompetencyItem = async (sectionIndex: number, itemIndex: number) => {
    if (!canUseAI) return;
    
    setImprovingCompetency({ sectionIndex, itemIndex });
    const section = form.watch(`competencySections.${sectionIndex}`);
    const currentText = Array.isArray(section.content) ? section.content[itemIndex] : section.content;
    
    try {
      const improvedText = await improveText(currentText, canUseAI);
      // Update the specific item in the array
      const newContent = [...(section.content as string[])];
      newContent[itemIndex] = improvedText;
      handleSubmitEdit(
        JSON.stringify(newContent), 
        `competencySections.${sectionIndex}.content` as FieldPath<CV>, 
        () => {}
      );
    } catch (error) {
      console.error('Failed to improve competency:', error);
    } finally {
      setImprovingCompetency(null);
    }
  };

  return (
    <div className="pb-12">
      <div className="mb-8">
        <ImportDialog onImport={onChange} />
      </div>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
          <Collapsible defaultOpen className="space-y-4">
            <div className="border-b border-border pb-2 mb-4 flex justify-between items-center">
              <div className="flex items-center gap-2 group">
                {editingSectionTitle === 'personalInfo' ? (
                  <InputWithSubmit
                    defaultValue={form.watch('sectionTitles.personalInfo')}
                    onSubmit={(value) => handleSubmitEdit(value, 'sectionTitles.personalInfo', () => setEditingSectionTitle(null))}
                    onCancel={() => setEditingSectionTitle(null)}
                    id="personalInfo-title"
                  />
                ) : (
                  <>
                    <h3 className="text-lg font-semibold text-foreground">
                      {form.watch('sectionTitles.personalInfo')}
                    </h3>
                    <Button
                      type="button"
                      variant="ghost"
                      size="sm"
                      onClick={() => setEditingSectionTitle('personalInfo')}
                      className="p-2 h-8 w-8 opacity-0 group-hover:opacity-100 transition-opacity"
                    >
                      <Pencil className="h-4 w-4" />
                    </Button>
                  </>
                )}
              </div>
              <Button
                type="button"
                variant="ghost"
                size="sm"
                onClick={() => toggleSectionVisibility('personalInfo')}
                className="p-2 h-8 w-8"
              >
                {form.watch('visibility.personalInfo') ? (
                  <Eye className="h-4 w-4" />
                ) : (
                  <EyeOff className="h-4 w-4" />
                )}
              </Button>
            </div>
            <CollapsibleContent className="space-y-4 pl-4 mt-4">
              <InputWithSubmit
                defaultValue={form.watch('name')}
                onSubmit={(value) => handleSubmitEdit(value, 'name', () => {})}
                onCancel={() => {}}
                id="name-input"
                className="flex-1 text-sm sm:text-base h-7 sm:h-8 min-h-[28px] sm:min-h-[32px]"
                placeholder="Full Name"
              />
              <div className="flex flex-1 items-center gap-1 sm:gap-2">
                <div className="flex-1">
                  <InputWithSubmit
                    defaultValue={form.watch('contact.picture.url')}
                    onSubmit={(value) => handleSubmitEdit(value, 'contact.picture.url' as FieldPath<CV>, () => {})}
                    onCancel={() => {}}
                    id="picture-url-input"
                    className="w-full text-sm sm:text-base h-7 sm:h-8 min-h-[28px] sm:min-h-[32px]"
                    placeholder="Picture URL"
                  />
                </div>
                <Button
                  type="button"
                  variant="ghost"
                  size="sm"
                  disabled={!form.watch('contact.picture.visible')}
                  onClick={() => {
                    form.setValue('contact.picture.round', !form.watch('contact.picture.round'));
                    form.handleSubmit(onSubmit)();
                  }}
                  className="p-1 sm:p-2 h-7 sm:h-8 w-7 sm:w-8"
                >
                  {form.watch('contact.picture.round') ? (
                    <div className="relative w-4 sm:w-5 h-4 sm:h-5">
                      <User className="h-3 sm:h-4 w-3 sm:w-4 absolute inset-0 m-auto p-0.5" />
                      <Circle className="h-4 sm:h-5 w-4 sm:w-5 stroke-2" />
                    </div>
                  ) : (
                    <div className="relative w-4 sm:w-5 h-4 sm:h-5">
                      <User className="h-3 sm:h-4 w-3 sm:w-4 absolute inset-0 m-auto p-0.5" />
                      <Square className="h-4 sm:h-5 w-4 sm:w-5 stroke-2" />
                    </div>
                  )}
                </Button>
                <Button
                  type="button"
                  variant="ghost"
                  size="sm"
                  onClick={() => {
                    form.setValue('contact.picture.visible', !form.watch('contact.picture.visible'));
                    form.handleSubmit(onSubmit)();
                  }}
                  className="p-1 sm:p-2 h-7 sm:h-8 w-7 sm:w-8"
                >
                  {form.watch('contact.picture.visible') ? (
                    <Eye className="h-3 sm:h-4 w-3 sm:w-4" />
                  ) : (
                    <EyeOff className="h-3 sm:h-4 w-3 sm:w-4" />
                  )}
                </Button>
              </div>
              <div className="grid grid-rows-3 gap-2 sm:gap-4">
                <InputWithSubmit
                  defaultValue={form.watch('contact.email')}
                  onSubmit={(value) => handleSubmitEdit(value, 'contact.email' as FieldPath<CV>, () => {})}
                  onCancel={() => {}}
                  id="email-input"
                  className="flex-1 text-sm sm:text-base h-7 sm:h-8 min-h-[28px] sm:min-h-[32px]"
                  placeholder="Email"
                />
                <InputWithSubmit
                  defaultValue={form.watch('contact.phone')}
                  onSubmit={(value) => handleSubmitEdit(value, 'contact.phone' as FieldPath<CV>, () => {})}
                  onCancel={() => {}}
                  id="phone-input"
                  className="flex-1 text-sm sm:text-base h-7 sm:h-8 min-h-[28px] sm:min-h-[32px]"
                  placeholder="Phone"
                />
                <InputWithSubmit
                  defaultValue={form.watch('contact.address')}
                  onSubmit={(value) => handleSubmitEdit(value, 'contact.address' as FieldPath<CV>, () => {})}
                  onCancel={() => {}}
                  id="address-input"
                  className="flex-1 text-sm sm:text-base h-7 sm:h-8 min-h-[28px] sm:min-h-[32px]"
                  placeholder="Address"
                />
              </div>
            </CollapsibleContent>
          </Collapsible>

          <Collapsible defaultOpen className="space-y-4">
            <div className="border-b border-border pb-2 mb-4 flex justify-between items-center">
              <div className="flex items-center gap-2 group">
                {editingSectionTitle === 'experience' ? (
                  <InputWithSubmit
                    defaultValue={form.watch('sectionTitles.experience')}
                    onSubmit={(value) => handleSubmitEdit(value, 'sectionTitles.experience', () => setEditingSectionTitle(null))}
                    onCancel={() => setEditingSectionTitle(null)}
                    id="experience-title"
                  />
                ) : (
                  <>
                    <h3 className="text-lg font-semibold text-foreground">
                      {form.watch('sectionTitles.experience')}
                    </h3>
                    <Button
                      type="button"
                      variant="ghost"
                      size="sm"
                      onClick={() => setEditingSectionTitle('experience')}
                      className="p-2 h-8 w-8 opacity-0 group-hover:opacity-100 transition-opacity"
                    >
                      <Pencil className="h-4 w-4" />
                    </Button>
                  </>
                )}
              </div>
              <div className="flex gap-2">
                <Button
                  type="button"
                  variant="ghost"
                  size="sm"
                  onClick={addExperience}
                  className="p-2 h-8 w-8"
                >
                  <Plus className="h-4 w-4" />
                </Button>
                <Button
                  type="button"
                  variant="ghost"
                  size="sm"
                  onClick={() => toggleSectionVisibility('experience')}
                  className="p-2 h-8 w-8"
                >
                  {form.watch('visibility.experience') ? (
                    <Eye className="h-4 w-4" />
                  ) : (
                    <EyeOff className="h-4 w-4" />
                  )}
                </Button>
              </div>
            </div>
            <CollapsibleContent className="space-y-4">
              <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={(event) => handleExperienceDragEnd(event)}
              >
                <SortableContext
                  items={data.experience.map((_, index) => `experience-${index}`)}
                  strategy={verticalListSortingStrategy}
                >
                  {data.experience.map((exp, index) => (
                    <SortableItem key={`experience-${index}`} id={`experience-${index}`}>
                      <Collapsible className="flex-1">
                        <div className="flex items-center gap-2">
                          <CollapsibleTrigger asChild>
                            <div className="flex items-center justify-between w-full p-2 hover:bg-accent cursor-pointer">
                              <div className="flex items-center gap-2 min-w-0 w-full">
                                <div className="flex-1 min-w-0">
                                  {editingSubsectionId === `experience-${index}` ? (
                                    <InputWithSubmit
                                      defaultValue={exp.company}
                                      onSubmit={(value) => handleSubmitEdit(value, `experience.${index}.company` as FieldPath<CV>, () => setEditingSubsectionId(null))}
                                      onCancel={() => setEditingSubsectionId(null)}
                                      id={`experience-${index}-company`}
                                      stopPropagation
                                    />
                                  ) : (
                                    <div className="flex items-center min-w-0">
                                      <span className="truncate text-sm break-words whitespace-normal">{exp.company}</span>
                                      <Button
                                        type="button"
                                        variant="ghost"
                                        size="sm"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          setEditingSubsectionId(`experience-${index}`);
                                        }}
                                        className="p-2 h-8 w-8 opacity-0 group-hover:opacity-100 transition-opacity ml-2 flex-shrink-0"
                                      >
                                        <Pencil className="h-4 w-4" />
                                      </Button>
                                    </div>
                                  )}
                                </div>
                                <ChevronDown className="h-4 w-4 text-muted-foreground flex-shrink-0" />
                              </div>
                            </div>
                          </CollapsibleTrigger>
                          <Button
                            type="button"
                            variant="ghost"
                            size="sm"
                            onClick={() => removeExperienceItem(index)}
                            className="p-2 h-8 w-8 flex-shrink-0"
                          >
                            <Trash2 className="h-4 w-4" />
                          </Button>
                        </div>
                        <CollapsibleContent className="space-y-2 p-4 pl-2 sm:space-y-4 sm:p-4">
                          <InputWithSubmit
                            defaultValue={form.watch(`experience.${index}.title`)}
                            onSubmit={(value) => handleSubmitEdit(value, `experience.${index}.title` as FieldPath<CV>, () => {})}
                            onCancel={() => {}}
                            id={`experience-${index}-title`}
                            className="flex-1 text-sm sm:text-base h-7 sm:h-8 min-h-[28px] sm:min-h-[32px]"
                            placeholder="Title"
                          />
                          <InputWithSubmit
                            defaultValue={form.watch(`experience.${index}.location`)}
                            onSubmit={(value) => handleSubmitEdit(value, `experience.${index}.location` as FieldPath<CV>, () => {})}
                            onCancel={() => {}}
                            id={`experience-${index}-location`}
                            className="flex-1 text-sm sm:text-base h-7 sm:h-8 min-h-[28px] sm:min-h-[32px]"
                            placeholder="Location"
                          />
                          <InputWithSubmit
                            defaultValue={form.watch(`experience.${index}.period`)}
                            onSubmit={(value) => handleSubmitEdit(value, `experience.${index}.period` as FieldPath<CV>, () => {})}
                            onCancel={() => {}}
                            id={`experience-${index}-period`}
                            className="flex-1 text-sm sm:text-base h-7 sm:h-8 min-h-[28px] sm:min-h-[32px]"
                            placeholder="Period"
                          />
                          <div className="space-y-2">
                            {exp.description.map((_, descIndex) => (
                              <div key={descIndex} className="flex items-start gap-1 sm:gap-2">
                                <div className="flex-1">
                                  <InputWithSubmit
                                    defaultValue={form.watch(`experience.${index}.description.${descIndex}`)}
                                    onSubmit={(value) => handleSubmitEdit(value, `experience.${index}.description.${descIndex}` as FieldPath<CV>, () => {})}
                                    onCancel={() => {}}
                                    id={`experience-${index}-description-${descIndex}`}
                                    className="w-full text-sm sm:text-base"
                                    placeholder={`Description ${descIndex + 1}`}
                                    isTextArea
                                  />
                                </div>
                                <AIButton 
                                  onClick={() => improveDescription(index, descIndex)}
                                  loading={improvingDescription?.experienceIndex === index && 
                                           improvingDescription?.descriptionIndex === descIndex}
                                  requiresPro={!canUseAI}
                                />
                                <Button
                                  type="button"
                                  variant="ghost"
                                  size="sm"
                                  onClick={() => removeDescriptionItem(index, descIndex)}
                                  className="p-2 h-8 w-8 flex-shrink-0"
                                >
                                  <Trash2 className="h-4 w-4" />
                                </Button>
                              </div>
                            ))}
                            <Button
                              type="button"
                              variant="ghost"
                              size="sm"
                              onClick={() => addDescription(index)}
                            >
                              Add Description
                            </Button>
                          </div>
                        </CollapsibleContent>
                      </Collapsible>
                    </SortableItem>
                  ))}
                </SortableContext>
              </DndContext>
            </CollapsibleContent>
          </Collapsible>

          <Collapsible defaultOpen className="space-y-4">
            <div className="border-b border-border pb-2 mb-4 flex justify-between items-center">
              <div className="flex items-center gap-2 group">
                {editingSectionTitle === 'education' ? (
                  <InputWithSubmit
                    defaultValue={form.watch('sectionTitles.education')}
                    onSubmit={(value) => handleSubmitEdit(value, 'sectionTitles.education', () => setEditingSectionTitle(null))}
                    onCancel={() => setEditingSectionTitle(null)}
                    id="education-title"
                  />
                ) : (
                  <>
                    <h3 className="text-lg font-semibold text-foreground">
                      {form.watch('sectionTitles.education')}
                    </h3>
                    <Button
                      type="button"
                      variant="ghost"
                      size="sm"
                      onClick={() => setEditingSectionTitle('education')}
                      className="p-2 h-8 w-8 opacity-0 group-hover:opacity-100 transition-opacity"
                    >
                      <Pencil className="h-4 w-4" />
                    </Button>
                  </>
                )}
              </div>
              <div className="flex gap-2">
                <Button
                  type="button"
                  variant="ghost"
                  size="sm"
                  onClick={addEducation}
                  className="p-2 h-8 w-8"
                >
                  <Plus className="h-4 w-4" />
                </Button>
                <Button
                  type="button"
                  variant="ghost"
                  size="sm"
                  onClick={() => toggleSectionVisibility('education')}
                  className="p-2 h-8 w-8"
                >
                  {form.watch('visibility.education') ? (
                    <Eye className="h-4 w-4" />
                  ) : (
                    <EyeOff className="h-4 w-4" />
                  )}
                </Button>
              </div>
            </div>
            <CollapsibleContent className="space-y-4">
              <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={(event) => handleEducationDragEnd(event)}
              >
                <SortableContext
                  items={data.education.map((_, index) => `education-${index}`)}
                  strategy={verticalListSortingStrategy}
                >
                  {data.education.map((edu, index) => (
                    <SortableItem key={`education-${index}`} id={`education-${index}`}>
                      <Collapsible className="flex-1">
                        <div className="flex items-center gap-2">
                          <CollapsibleTrigger asChild>
                            <div className="flex items-center justify-between w-full p-2 hover:bg-accent cursor-pointer">
                              <div className="flex items-center gap-2 min-w-0 w-full">
                                <div className="flex-1 min-w-0">
                                  {editingSubsectionId === `education-${index}` ? (
                                    <InputWithSubmit
                                      defaultValue={edu.institution}
                                      onSubmit={(value) => handleSubmitEdit(value, `education.${index}.institution` as FieldPath<CV>, () => setEditingSubsectionId(null))}
                                      onCancel={() => setEditingSubsectionId(null)}
                                      id={`education-${index}-institution`}
                                      stopPropagation
                                    />
                                  ) : (
                                    <div className="flex items-center min-w-0">
                                      <span className="truncate text-sm break-words whitespace-normal">{edu.institution}</span>
                                      <Button
                                        type="button"
                                        variant="ghost"
                                        size="sm"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          setEditingSubsectionId(`education-${index}`);
                                        }}
                                        className="p-2 h-8 w-8 opacity-0 group-hover:opacity-100 transition-opacity ml-2 flex-shrink-0"
                                      >
                                        <Pencil className="h-4 w-4" />
                                      </Button>
                                    </div>
                                  )}
                                </div>
                                <ChevronDown className="h-4 w-4 text-muted-foreground flex-shrink-0" />
                              </div>
                            </div>
                          </CollapsibleTrigger>
                          <Button
                            type="button"
                            variant="ghost"
                            size="sm"
                            onClick={() => removeEducationItem(index)}
                            className="p-2 h-8 w-8 flex-shrink-0"
                          >
                            <Trash2 className="h-4 w-4" />
                          </Button>
                        </div>
                        <CollapsibleContent className="space-y-2 p-4 pl-2 sm:space-y-4 sm:p-4">
                          <InputWithSubmit
                            defaultValue={form.watch(`education.${index}.degree`)}
                            onSubmit={(value) => handleSubmitEdit(value, `education.${index}.degree` as FieldPath<CV>, () => {})}
                            onCancel={() => {}}
                            id={`education-${index}-degree`}
                            className="flex-1 text-sm sm:text-base h-7 sm:h-8 min-h-[28px] sm:min-h-[32px]"
                            placeholder="Degree"
                          />
                          <InputWithSubmit
                            defaultValue={form.watch(`education.${index}.period`)}
                            onSubmit={(value) => handleSubmitEdit(value, `education.${index}.period` as FieldPath<CV>, () => {})}
                            onCancel={() => {}}
                            id={`education-${index}-period`}
                            className="flex-1 text-sm sm:text-base h-7 sm:h-8 min-h-[28px] sm:min-h-[32px]"
                            placeholder="Period"
                          />
                        </CollapsibleContent>
                      </Collapsible>
                    </SortableItem>
                  ))}
                </SortableContext>
              </DndContext>
            </CollapsibleContent>
          </Collapsible>

          <Collapsible defaultOpen className="space-y-4">
            <div className="border-b border-border pb-2 mb-4 flex justify-between items-center">
              <div className="flex items-center gap-2 group">
                {editingSectionTitle === 'competencies' ? (
                  <InputWithSubmit
                    defaultValue={form.watch('sectionTitles.competencies')}
                    onSubmit={(value) => handleSubmitEdit(value, 'sectionTitles.competencies', () => setEditingSectionTitle(null))}
                    onCancel={() => setEditingSectionTitle(null)}
                    id="competencies-title"
                  />
                ) : (
                  <>
                    <h3 className="text-lg font-semibold text-foreground">
                      {form.watch('sectionTitles.competencies')}
                    </h3>
                    <Button
                      type="button"
                      variant="ghost"
                      size="sm"
                      onClick={() => setEditingSectionTitle('competencies')}
                      className="p-2 h-8 w-8 opacity-0 group-hover:opacity-100 transition-opacity"
                    >
                      <Pencil className="h-4 w-4" />
                    </Button>
                  </>
                )}
              </div>
              <div className="flex gap-2">
                <Button
                  type="button"
                  variant="ghost"
                  size="sm"
                  onClick={addCompetencySection}
                  className="p-2 h-8 w-8"
                >
                  <Plus className="h-4 w-4" />
                </Button>
                <Button
                  type="button"
                  variant="ghost"
                  size="sm"
                  onClick={() => toggleSectionVisibility('competencySections')}
                  className="p-2 h-8 w-8"
                >
                  {form.watch('visibility.competencySections') ? (
                    <Eye className="h-4 w-4" />
                  ) : (
                    <EyeOff className="h-4 w-4" />
                  )}
                </Button>
              </div>
            </div>
            <CollapsibleContent className="space-y-4">
              <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={(event) => handleCompetencySectionsDragEnd(event)}
              >
                <SortableContext
                  items={data.competencySections.map((_, index) => `competency-${index}`)}
                  strategy={verticalListSortingStrategy}
                >
                  {data.competencySections.map((section, index) => (
                    <SortableItem key={`competency-${index}`} id={`competency-${index}`}>
                      <Collapsible className="flex-1">
                        <div className="flex items-center gap-2">
                          <CollapsibleTrigger asChild>
                            <div className="flex items-center justify-between w-full p-2 hover:bg-accent cursor-pointer">
                              <div className="flex items-center gap-2 min-w-0 w-full">
                                <div className="flex-1 min-w-0">
                                  {editingSubsectionId === `competency-${index}` ? (
                                    <InputWithSubmit
                                      defaultValue={section.title}
                                      onSubmit={(value) => handleSubmitEdit(value, `competencySections.${index}.title` as FieldPath<CV>, () => setEditingSubsectionId(null))}
                                      onCancel={() => setEditingSubsectionId(null)}
                                      id={`competency-${index}-title`}
                                      stopPropagation
                                    />
                                  ) : (
                                    <div className="flex items-center min-w-0">
                                      <span className="truncate text-sm break-words whitespace-normal">{section.title}</span>
                                      <Button
                                        type="button"
                                        variant="ghost"
                                        size="sm"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          setEditingSubsectionId(`competency-${index}`);
                                        }}
                                        className="p-2 h-8 w-8 opacity-0 group-hover:opacity-100 transition-opacity ml-2 flex-shrink-0"
                                      >
                                        <Pencil className="h-4 w-4" />
                                      </Button>
                                    </div>
                                  )}
                                </div>
                                <ChevronDown className="h-4 w-4 text-muted-foreground flex-shrink-0" />
                              </div>
                            </div>
                          </CollapsibleTrigger>
                          <Button
                            type="button"
                            variant="ghost"
                            size="sm"
                            onClick={() => {
                              form.setValue(
                                `competencySections.${index}.visible`,
                                !section.visible,
                                { shouldDirty: true }
                              );
                              form.handleSubmit(onSubmit)();
                            }}
                            className="p-2 h-8 w-8 flex-shrink-0"
                          >
                            {section.visible ? (
                              <Eye className="h-4 w-4" />
                            ) : (
                              <EyeOff className="h-4 w-4" />
                            )}
                          </Button>
                          <Button
                            type="button"
                            variant="ghost"
                            size="sm"
                            onClick={() => removeCompetencySection(index)}
                            className="p-2 h-8 w-8 flex-shrink-0"
                          >
                            <Trash2 className="h-4 w-4" />
                          </Button>
                        </div>
                        <CollapsibleContent className="space-y-2 pl-2 sm:space-y-4 sm:pl-4">
                          <div className="flex items-center gap-1 sm:gap-2">
                            <Button
                              type="button"
                              variant={section.type === 'list' ? 'default' : 'outline'}
                              size="sm"
                              onClick={() => {
                                const newContent = typeof section.content === 'string' ? [section.content] : section.content;
                                form.setValue(`competencySections.${index}`, {
                                  ...section,
                                  type: 'list',
                                  content: newContent
                                });
                                form.handleSubmit(onSubmit)();
                              }}
                            >
                              List
                            </Button>
                            <Button
                              type="button"
                              variant={section.type === 'paragraph' ? 'default' : 'outline'}
                              size="sm"
                              onClick={() => {
                                const newContent = Array.isArray(section.content) ? section.content.join(' ') : section.content;
                                form.setValue(`competencySections.${index}`, {
                                  ...section,
                                  type: 'paragraph',
                                  content: newContent
                                });
                                form.handleSubmit(onSubmit)();
                              }}
                            >
                              Paragraph
                            </Button>
                          </div>
                          {section.type === 'list' ? (
                            <div className="space-y-4">
                              <div className="flex items-center space-x-2">
                                <Checkbox 
                                  id={`inline-${section.id}`}
                                  checked={section.displayInline}
                                  onCheckedChange={(checked: boolean) => {
                                    form.setValue(`competencySections.${index}.displayInline`, checked);
                                    form.handleSubmit(onSubmit)();
                                  }}
                                />
                                <label
                                  htmlFor={`inline-${section.id}`}
                                  className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                                >
                                  Show list inline
                                </label>
                              </div>
                              <div className="space-y-2">
                                {(section.content as string[]).map((content, itemIndex: number) => (
                                  <div key={itemIndex} className="flex items-center gap-1 sm:gap-2">
                                    <div className="flex-1">
                                      <InputWithSubmit
                                        defaultValue={content}
                                        onSubmit={(value) => {
                                          const newContent = [...(section.content as string[])];
                                          newContent[itemIndex] = value;
                                          handleSubmitEdit(JSON.stringify(newContent), `competencySections.${index}.content` as FieldPath<CV>, () => {});
                                        }}
                                        onCancel={() => {}}
                                        id={`competency-${index}-item-${itemIndex}`}
                                        className="w-full text-sm sm:text-base h-7 sm:h-8 min-h-[28px] sm:min-h-[32px]"
                                        placeholder={`Item ${itemIndex + 1}`}
                                      />
                                    </div>
                                    <AIButton 
                                      onClick={() => improveCompetencyItem(index, itemIndex)}
                                      loading={improvingCompetency?.sectionIndex === index && 
                                               improvingCompetency?.itemIndex === itemIndex}
                                      requiresPro={!canUseAI}
                                      tooltipSide="right"
                                    />
                                    <Button
                                      type="button"
                                      variant="ghost"
                                      size="sm"
                                      onClick={() => {
                                        const newContent = [...(section.content as string[])];
                                        newContent.splice(itemIndex, 1);
                                        form.setValue(`competencySections.${index}.content`, newContent);
                                        form.handleSubmit(onSubmit)();
                                      }}
                                      className="p-2 h-8 w-8 flex-shrink-0"
                                    >
                                      <Trash2 className="h-4 w-4" />
                                    </Button>
                                  </div>
                                ))}
                                <Button
                                  type="button"
                                  variant="outline"
                                  size="sm"
                                  onClick={() => {
                                    const newContent = [...(section.content as string[]), ''];
                                    form.setValue(`competencySections.${index}.content`, newContent);
                                    form.handleSubmit(onSubmit)();
                                  }}
                                >
                                  Add Item
                                </Button>
                              </div>
                            </div>
                          ) : (
                            <InputWithSubmit
                              defaultValue={section.content as string}
                              onSubmit={(value) => handleSubmitEdit(value, `competencySections.${index}.content` as FieldPath<CV>, () => {})}
                              onCancel={() => {}}
                              id={`competency-${index}-content`}
                              className="flex-1 text-sm sm:text-base h-7 sm:h-8 min-h-[28px] sm:min-h-[32px]"
                              isTextArea
                            />
                          )}
                        </CollapsibleContent>
                      </Collapsible>
                    </SortableItem>
                  ))}
                </SortableContext>
              </DndContext>
            </CollapsibleContent>
          </Collapsible>

        </form>
      </Form>
    </div>
  )
} 