import { useAuth } from '@/contexts/AuthContext';

export const FEATURES = {
  PRO_ENABLED: import.meta.env.VITE_ENABLE_PRO === 'true',
} as const;

// Helper hook to check if user has pro features
export const useProFeatures = () => {
  const { isPro } = useAuth();
  
  return {
    isPro,
    // Add more pro features here as needed
    canUseAI: isPro,
    canExportPDF: isPro,
    // etc...
  };
}; 