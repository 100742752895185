import { Button } from "@/components/ui/button"
import { Sparkles } from "lucide-react"
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip"
import { toast } from "@/hooks/use-toast"
import { UpgradeProDialog } from "@/components/ui/upgrade-pro-dialog"

interface AIButtonProps {
  onClick: () => Promise<void>;
  disabled?: boolean;
  loading?: boolean;
  tooltipSide?: "top" | "right" | "bottom" | "left";
  requiresPro?: boolean;
}

export function AIButton({ 
  onClick, 
  disabled, 
  loading, 
  tooltipSide = "top",
  requiresPro = false 
}: AIButtonProps) {
  const handleClick = async () => {
    try {
      await onClick();
    } catch (error) {
      console.error('AI Button Error:', error);
      toast({
        title: "Error",
        description: "Failed to improve text. Please try again.",
        variant: "destructive"
      });
    }
  };

  const button = (
    <Button
      type="button"
      variant="ghost"
      size="sm"
      onClick={handleClick}
      disabled={disabled || loading}
      className="p-2 h-8 w-8 flex-shrink-0"
    >
      <Sparkles className={`h-4 w-4 ${loading ? 'animate-pulse' : ''}`} />
    </Button>
  );

  if (requiresPro) {
    return (
      <UpgradeProDialog>
        <Button
          type="button"
          variant="ghost"
          size="sm"
          className="p-2 h-8 w-8 flex-shrink-0"
        >
          <Sparkles className="h-4 w-4" />
        </Button>
      </UpgradeProDialog>
    );
  }

  if (disabled) {
    return button;
  }

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          {button}
        </TooltipTrigger>
        <TooltipContent side={tooltipSide}>
          <p>Improve with AI</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
} 