import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog"
import { Button } from "@/components/ui/button"
import { Wand2 } from "lucide-react"

interface ProFeatureDialogProps {
  children: React.ReactNode
  feature: "AI Improvements" | "PDF Export" // Add more features as needed
  description?: string
}

export function ProFeatureDialog({ 
  children, 
  feature,
  description = `Upgrade to Pro to unlock ${feature} and other premium features, including:
  • AI-powered content improvements
  • Smart import
  • Job spec matching`
}: ProFeatureDialogProps) {
  return (
    <Dialog>
      <DialogTrigger asChild>
        {children}
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle className="flex gap-2 items-center">
            <Wand2 className="h-5 w-5" />
            Upgrade to Pro
          </DialogTitle>
          <DialogDescription className="pt-2 whitespace-pre-line">
            {description}
          </DialogDescription>
        </DialogHeader>
        <div className="pt-4">
          <Button 
            className="w-full" 
            onClick={() => {/* TODO: Add upgrade logic */}}
          >
            Upgrade to Pro
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
} 