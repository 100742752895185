import { ProFeatureDialog } from "@/components/ui/pro-feature-dialog"

interface UpgradeProDialogProps {
  children: React.ReactNode;
  feature?: "AI Improvements" | "PDF Export";
}

export function UpgradeProDialog({ 
  children, 
  feature = "AI Improvements" 
}: UpgradeProDialogProps) {
  const descriptions = {
    "AI Improvements": `Let AI help you improve your CV content with professional wording and impactful descriptions.
    
Pro features include:
• PDF CV import
• AI-powered content improvements
• Smart formatting`,
    "PDF Export": `Export your CV to PDF with professional formatting.
    
Pro features include:
• PDF export
• Custom styling
• Multiple templates`
  };

  return (
    <ProFeatureDialog 
      feature={feature}
      description={descriptions[feature]}
    >
      {children}
    </ProFeatureDialog>
  );
} 