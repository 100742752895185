import { Button } from "@/components/ui/button"
import { Printer } from "lucide-react"
import type { ButtonProps } from "@/components/ui/button"
import { cn } from "@/lib/utils"

type PrintButtonProps = Pick<ButtonProps, 'variant' | 'className'>

export function PrintButton({ variant = 'default', className }: PrintButtonProps) {
  const handlePrint = () => {
    window.print()
  }

  const isIconOnly = className?.includes('w-8')

  return (
    <Button 
      variant={variant}
      size={isIconOnly ? "icon" : "sm"}
      onClick={handlePrint}
      className={cn("print:hidden", className)}
    >
      <Printer className={cn("h-4 w-4", !isIconOnly && "mr-2")} />
      {!isIconOnly && "Print"}
    </Button>
  )
} 