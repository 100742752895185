import { CompetencySection as CompetencySectionType } from "@/types/cv"
import { Section } from "./section"

interface CompetencySectionProps {
  section: CompetencySectionType;
}

export function CompetencySection({ section }: CompetencySectionProps) {
  if (!section.visible) return null;

  return (
    <div className="space-y-2 md:space-y-4">
      <h3 className="font-semibold mb-1.5 md:mb-2 text-foreground text-base md:text-lg">{section.title}</h3>
      {section.type === 'list' ? (
        section.displayInline ? (
          <p className="text-sm text-muted-foreground leading-normal">
            {(section.content as string[]).join(" • ")}
          </p>
        ) : (
          <ul className="list-disc list-inside text-sm space-y-0.5 md:space-y-1 text-muted-foreground pl-1 md:pl-0">
            {(section.content as string[]).map((item, index) => (
              <li key={index} className="leading-normal">{item}</li>
            ))}
          </ul>
        )
      ) : (
        <p className="text-sm text-muted-foreground leading-normal">{section.content as string}</p>
      )}
    </div>
  )
}

interface CompetencySectionsProps {
  sections: CompetencySectionType[];
  title: string;
}

export function CompetencySections({ sections, title }: CompetencySectionsProps) {
  const visibleSections = sections.filter(section => section.visible);
  
  if (visibleSections.length === 0) return null;

  return (
    <Section title={title}>
      <div className="space-y-4 md:space-y-6">
        {visibleSections.map((section) => (
          <CompetencySection key={section.id} section={section} />
        ))}
      </div>
    </Section>
  )
} 